import { Entity } from 'src/app/core/utils/entity';

export abstract class ClaimEntity extends Entity {
  constructor(
    public department_id: string,
    public claimetLastname: string,
    public claimetName: string,
    public claimetCuil: string,
    public claimetEmail: string,
    public claimetPhone: string,
    public claimetProvince: string,
    public claimetCity: string,
    public claimetNeighborhood: string,
    public claimetStreet: string,
    public claimetNumber: string,
    public claimetPostalCode: string,
    public claimetSelectedTypeOfJob: string,
    public claimetWorkday: string,
    public claimetGrossRemuneration: string,
    public claimetTypeRemuneration: string,
    public claimetDateAdmission: Date,
    public claimetNumberAgreement: string,
    public claimetYear: Date,
    public claimetCategory: string,
    public claimetCurrentEmploymentStatus: string,
    public claimetFileDni: string[],
    public claimetFilePaycheck: string[],
    public claimedTypeOfPerson: string,
    public claimedNameLastNameOrBusinessName: string,
    public claimedCuil: string,
    public claimedEmail: string,
    public claimedPhone: string,
    public claimedStreet: string,
    public claimedNumber: string,
    public claimedPostalCode: string,
    public claimedNeighborhood: string,
    public claimedProvince: string,
    public claimedCity: string,
    public selecterObjectOfClaim: string,
    public textClaimDetails: string,
    public extraDocumentationPdf: string,
    public selectedOwnRight: string,
    public lawyerFullName: string,
    public lawyerRegistrationNumber: string,
    public lawyerEmail: string,
    public user_id: string,
    public claimetFloorApartament?: string,
    public claimetTower?: string,
    public confirmInformation?: string,
    public claimetEgressDate?: Date,
    public claimetReasonForDisengagement?: string,
    public claimetCauseDisengagement?: string,
    public claimedFloorApartment?: string,
    public claimedTower?: string,
    public proceedings?: string,
    public expediente_claim?: string,
    public number_of_claim?: string,
    public status?: string,
    public created?: string,
    public reasonForRejection?: string,
    public conciliatorId?: string,
    public conciliatorFullName?: string,
    public is_borrador?: boolean,
    public department_number?: string,
    public department_name?: string,
    public id?: string,
  ) {
    super();
  }
}
