<p-toast />
<section
  class="filter animated surface-border border-200 border-1"
  *ngIf="showFilter"
>
  <div class="container">
    <div class="first-part">
      <h4 class="title">Filtros</h4>
      <i
        (click)="closeFilter()"
        class="pi pi-times icon"
        style="font-size: 1rem; cursor: pointer"
      ></i>
    </div>
    <hr class="line" />
    <div class="flex flex-column gap-4">
      <div class="w-full">
        <div class="flex flex-column w-full">
          <label
            style="font-size: small; font-weight: bold; margin-bottom: 10px"
            for=""
            >Estado</label
          >
          <p-multiSelect
            class="states"
            [options]="stateList"
            [(ngModel)]="selectedStates"
            placeholder="Seleccione el estado"
            filter="false"
          />
        </div>
      </div>
      <div class="w-full">
        <div class="flex flex-column w-full">
          <label
            style="font-size: small; font-weight: bold; margin-bottom: 10px"
            for=""
            >Fecha de Inicio</label
          >
          <p-calendar
            class="calendar"
            [(ngModel)]="selectedDate"
            [iconDisplay]="'input'"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
            inputId="icondisplay"
            placeholder="Seleccione la fecha de inicio"
          />
        </div>
      </div>
      <div class="w-full">
        <div class="flex flex-column w-full">
          <label
            style="font-size: small; font-weight: bold; margin-bottom: 10px"
            for=""
            >Departamento de Santa Fe</label
          >
          <p-dropdown
            class="states"
            [options]="departmentList"
            [(ngModel)]="selectedDepartment"
            optionValue="department_number"
            optionLabel="name"
            placeholder="Seleccione el departamento"
            filter="false"
          />
        </div>
      </div>
      <div class="w-full">
        <div class="flex flex-column w-full">
          <label
            style="font-size: small; font-weight: bold; margin-bottom: 10px"
            for=""
            >Conciliador</label
          >
          <p-dropdown
            class="states"
            [options]="conciliatorList"
            [(ngModel)]="selectedconciliator"
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccione el conciliador"
            filter="false"
          />
        </div>
      </div>
    </div>
    <div class="container-buttons">
      <button class="btn-light cancel" (click)="cleanFilter()">
        Limpiar filtros
      </button>
      <button class="btn-plus" (click)="applyFilters()">Aplicar filtros</button>
    </div>
  </div>
</section>
