import { AudienceEntity } from "../../domain/entities/audience.entity";

export class AudienceModel extends AudienceEntity {
    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('date', this.date);
        json.set('time', this.time);
        json.set('audience_title', this.audienceTitle);
        json.set('claimant_id', this.claimetId);
        json.set('claimed_id', this.claimedId);
        json.set('claim_id', this.claimId);
        json.set('claimant_name', this.claimetName);
        json.set('claimant_last_name', this.claimetLastname);
        json.set('business_name_claimed', this.claimedNameLastNameOrBusinessName);
        json.set('conciliator', this.conciliator);
        json.set('proceedings', this.proceedings);
        json.set('number_of_claim', this.numberOfClaim);
        json.set('audience_number', this.audienceNumber);
        json.set('conciliator_id', this.conciliatorId);
        json.set('claimant_email', this.claimetEmail);
        json.set('claimed_email', this.claimedEmail);
        json.set('id', this.id);
        json.set('link_google_meet', this.link_google_meet);
        json.set('expediente_claim', this.expediente_claim);
        return json;
    }

    static fromJson(data: Map<string,any>): AudienceEntity {
        return new AudienceModel(
            data.get('date'),
            data.get('time'),
            data.get('audience_title'),
            data.get('claimant_id'),
            data.get('claimed_id'),
            data.get('claim_id'),
            data.get('claimant_name'),
            data.get('claimant_last_name'),
            data.get('business_name_claimed'),
            data.get('conciliator'),
            data.get('proceedings'),
            data.get('number_of_claim'),
            data.get('audience_number'),
            data.get('conciliator_id'),
            data.get('claimant_email'),
            data.get('claimed_email'),
            data.get('id'),
            data.get('link_google_meet'),
            data.get('expediente_claim'),
        );
    }

}
