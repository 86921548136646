import { Entity } from "src/app/core/utils/entity";

export abstract class ClaimData extends Entity{
    constructor(
        public id: string,
        public claimentId: string,
        public claimentName: string,
        public claimentLastName: string,
        public claimentCuil: string,
        public startDate: string,
        public status: string,
        public proceedings: string,
        public claimedId: string,
        public nameLastNameOrBusinessNameClaimed: string,
        public claimedCuil: string,
        public nameLastNameOrBusinessNameClaimet: string,
        public conciliatorId: string,
        public conciliatorFullName: string,
        public number_of_claim: string,
        public expediente_claim: string,
    ){
        super();
    }
}
