import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/core/services/local-storage.implementation.service';
import { CalendarTranslationService } from 'src/app/core/services/translate-calendar.service';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { Failure } from 'src/app/core/utils/failure';
import { AudienceEntity } from 'src/app/features/audiences/domain/entities/audience.entity';
import { GetNextAudienceOfClaimantUseCase } from 'src/app/features/audiences/domain/usecases/get-next-audience-of-claimant.usecase';
import { ClaimServiceService } from 'src/app/features/claims/services/claim-service.service';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { UserService } from 'src/app/features/user/services/user.service';

@Component({
  selector: 'app-simple-rol-home',
  templateUrl: './simple-rol-home.component.html',
  styleUrls: ['./simple-rol-home.component.css'],
})
export class SimpleRolHomeComponent implements OnInit {
  tableOrImg: boolean = false;
  @Input() userData?: UserData;
  rol: string | null = localStorage.getItem('user_rol');
  claimsQuantity = localStorage.getItem('claims_quantity');
  claims: boolean = false;
  activeClaims: boolean = false;
  private AES = new AESEncryptDecryptService();
  public audience?: AudienceEntity;
  public audienceToday: boolean = false;
  
  constructor(
    private userService: UserService,
    private claimService: ClaimServiceService,
    private calendarTranslationService: CalendarTranslationService,
    private getNextAudienceOfClaimantUseCase: GetNextAudienceOfClaimantUseCase,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.calendarTranslationService.configureCalendarTranslations();
    this.getClaimsData();
    this.getNextAudience();
  }

  private async getNextAudience() {
    const idUser = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_id')
    );
    const result = await this.getNextAudienceOfClaimantUseCase.execute(idUser);
    if (result instanceof Failure) {
      return;
    }
    this.audience = result;
    this.audienceToday = this.verificateAudienceDate(this.audience.date);
  }

  verificateAudienceDate(date: string): boolean {
    const today = new Date().setHours(0, 0, 0, 0); 
    const dateAudience = new Date(date).setHours(0, 0, 0, 0);     
    return today === dateAudience; 
  }

  public openMeet(){
    if (this.audience?.link_google_meet !== undefined) {
      window.open(this.audience?.link_google_meet, '_blank');
    }
  }

  getUserData(): void {
    this.userService.userData$.subscribe((userData) => {
      if (!userData) return;
      this.userData = userData;
      this.rol = this.userData?.rol;
    });
  }

  getClaimsData(): void {
    this.claimService.claims$.subscribe((claims) => {
      if (!claims) {
        this.claims = false;
      } else {
        this.claims = true;
      }
    });
  }

  isActiveClaims(event: any): void {
    this.activeClaims = event;
  }
}
