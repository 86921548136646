import { AdminUser } from '../../domain/entities/AdminUser.entity';

export class UserAdminModel extends AdminUser {
  override toJson(): Map<string, any> {
    let map: Map<string, any> = new Map<string, any>();
    map.set('rol', this.rol);
    map.set('name', this.name);
    map.set('last_name', this.last_name);
    map.set('cuil', this.cuil);
    map.set('phone', this.phone);
    map.set('dni', this.dni);
    map.set('province', this.province);
    map.set('city', this.city);
    map.set('street', this.street);
    map.set('number', this.number);
    map.set('floor_apartment', this.floor_apartment);
    map.set('postal_code', this.postal_code);
    map.set('email', this.email);
    map.set('tower', this.tower);
    map.set('id', this.id);
    map.set('gender', this.gender);
    map.set('birth_date', this.birth_date);
    map.set('civil_status', this.civil_status);
    map.set('is_conciliator', this.is_conciliator);
    map.set('is_comision', this.is_comision);
    map.set('is_administrator', this.is_administrator);
    return map;
  }

  static fromJson(data: Map<string, any>): AdminUser {
    return new UserAdminModel(
      data.get('rol'),
      data.get('name'),
      data.get('last_name'),
      data.get('cuil'),
      data.get('phone'),
      data.get('dni'),
      data.get('province'),
      data.get('city'),
      data.get('street'),
      data.get('number'),
      data.get('floor_apartment'),
      data.get('postal_code'),
      data.get('email'),
      data.get('tower'),
      data.get('id'),
      data.get('gender'),
      data.get('birth_date'),
      data.get('civil_status'),
      data.get('is_conciliator'),
      data.get('is_comision'),
      data.get('is_administrator')
    );
  }
}
