import { UseCase } from "src/app/core/utils/usecase";
import { ClaimEntity } from "../entities/claim.entity";
import { Failure } from "src/app/core/utils/failure";
import { ClaimRepositoryBase } from "../repositories/claim-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class CreateClaimUseCase implements UseCase<ClaimEntity, string | Failure> {
    constructor(private claimRepository: ClaimRepositoryBase) {}

    async execute(param: ClaimEntity): Promise<string | Failure> {
        return await this.claimRepository.createClaim(param);
    }
}
