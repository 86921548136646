import { Failure } from "src/app/core/utils/failure";
import { UserData } from "../entities/user-data.entity";
import { DeleteUserEntity } from "../entities/delete-user.entity";
import { UserPaginationParams } from "../entities/user-pagination-params.entity";
import { UserPagination } from "../entities/user-pagination.entity";
import { ComisionUser } from "../entities/comision-user.entity";
import { AdminUser } from "../entities/AdminUser.entity";

export abstract class UserRepositoryBase {
    abstract getMyData(): Promise<UserData | Failure>;
    abstract getAllConciliators(): Promise<UserData[] | Failure>;

    abstract getUser(id:string): Promise<UserData | Failure>;
    abstract getPaginationUser(params:UserPaginationParams): Promise<UserPagination | Failure>;
    abstract getAllPaginationUser(params:UserPaginationParams): Promise<UserPagination | Failure>;
    abstract updateUser(User:ComisionUser): Promise<string | Failure>;
    abstract updateUserAdmin(User:AdminUser): Promise<string | Failure>;
    abstract deleteUser(deleteUser:DeleteUserEntity): Promise<string | Failure>;
    abstract createUser(User:ComisionUser): Promise<string | Failure>;
}
