import { ClaimsPaginationParams } from "../../domain/entities/claim-pagination-params.entity";

export class ClaimsPaginationParamsModel extends ClaimsPaginationParams {

    override getPath(): string {
        let url: string = '';
        
        if (this.userId) {
            url += `${this.userId}?`;
        } else {
            url += '?';
        }
        if (this.filters?.departmentId) url += `department_number=${this.filters?.departmentId}&`;
        if (this.filters?.conciliatorId) url += `conciliator_id=${this.filters?.conciliatorId}&`;
        if (this.filters?.fromDate)  url += `start_date=${this.filters.fromDate.toISOString()}&`
        if (this.page >= 1) this.page--;
        
        if (this.filters?.states) {
            this.filters.states.forEach((state: string) => {
                url += `status=${state}&`
            });
        }
        url += `pagination.IsPaginated=true`
        url += `&pagination.Page=${this.page}`
        url += `&pagination.AmountRegistersPage=${this.quantity}`
        return url;
    }
    override toJson(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

}