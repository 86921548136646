import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimengModule } from 'src/app/core/components/primeng/primeng.module';
import { MainRoutingModule } from './main-routing.module';
import { MainBaseComponent } from './presentation/pages/main-base/main-base.component';
import { MyLaborClaims } from './presentation/components/myLaborClaims/myLaborClaims.component';
import { SharedModule } from 'src/app/core/shared.module';
import { HomeComponent } from './presentation/components/home/home.component';
import { UserRemoteDataSourceBase, UserRemoteDataSource } from '../user/data/data-sources/user-remote.datasource';
import { UserRepositoryBase } from '../user/domain/repositories/user-base.repository';
import { UserRepository } from '../user/data/repositories/user.repository';
import { GetMyDataUseCase } from '../user/domain/usecases/get-my-data.usecase';
import { ClaimRemoteDataSource, ClaimRemoteDataSourceBase } from '../claims/data/data-source/claim-remote.datasource';
import { ClaimRepositoryBase } from '../claims/domain/repositories/claim-base.repository';
import { ClaimRepository } from '../claims/data/repositories/claim.repository';
import { GetPaginatedClaimsUseCase } from '../claims/domain/usecases/get-paginated-claims.usecase';
import { ClaimsTableComponent } from '../claims/presentation/components/claims-table/claims-table.component';
import { ConfigurationComponent } from '../user/presentation/pages/configuration/configuration.component';
import { ResetPasswordComponent } from '../user/presentation/components/reset-password/reset-password.component';
import { ClaimViewComponent } from '../claims/presentation/components/claim-view/claim-view.component';
import { ClaimUseCase } from '../claims/domain/usecases/get-claim.usecase';
import { UpdateClaimUseCase } from '../claims/domain/usecases/update-claim.usecase';
import { DeleteClaimUseCase } from '../claims/domain/usecases/delete-claim.usecase';
import { ClaimComponent } from '../claims/presentation/components/claim/claim.component';
import { CreateClaimUseCase } from '../claims/domain/usecases/create-claim.usecase';
import { ClaimantDetailsFormComponent } from '../claims/presentation/components/claim/claimant-details-form/claimant-details-form.component';
import { ClaimedDetailsFormComponent } from '../claims/presentation/components/claim/claimed-details-form/claimed-details-form.component';
import { ClaimDetailsFormComponent } from '../claims/presentation/components/claim/claim-details-form/claim-details-form.component';
import { AudienceRemoteDataSource, AudienceRemoteDataSourceBase } from '../audiences/data/data-source/audience-remote.datasource';
import { AudienceRepositoryBase } from '../audiences/domain/repositories/aucience-base.repository';
import { AudienceRepository } from '../audiences/data/repositories/audience.repository';
import { AudienceUseCase } from '../audiences/domain/usecases/get-audience.usecase';
import { AudienceTableComponent } from '../audiences/presentation/components/audience-table/audience-table.component';
import { GetPaginationAudiencesUseCase } from '../audiences/domain/usecases/get-pagination-audiences.usecase';
import { CreateAudienceUseCase } from '../audiences/domain/usecases/create-audience.usecase';
import { DeleteAudienceUseCase } from '../audiences/domain/usecases/delete-audience.usecase';
import { UpdateAudienceUseCase } from '../audiences/domain/usecases/update-audience.usecase';
import { ClaimsComisionTableComponent } from '../claims/presentation/components/claims-comision-table/claims-comision-table.component';
import { ComisionHomeComponent } from './presentation/components/home/comision-home/comision-home.component';
import { AudiencesComponent } from './presentation/components/audiences/audiences.component';
import { SimpleRolHomeComponent } from './presentation/components/home/simple-rol-home/simple-rol-home.component';
import { GetPaginatedAllClaimsUseCase } from '../claims/domain/usecases/get-pagination-all-claims.usecase';
import { GetFileUseCase } from '../claims/domain/usecases/get-file.usecase';
import { GetPendingQuantityClaimsUseCase } from '../claims/domain/usecases/get-pending-quantity-claims.usecase';
import { GetAllConciliatorsUseCase } from '../user/domain/usecases/get-all-conciliators.usecase';
import { UpdateClaimStatusUseCase } from '../claims/domain/usecases/update-claim-status.usecase';
import { UpdateClaimConciliatorUseCase } from '../claims/domain/usecases/update-claim-conciliator.usecase';
import { GetNextAudienceOfConciliatorUseCase } from '../audiences/domain/usecases/get-next-audience-of-conciliator.usecase';
import { GetNextSetAudienceUseCase } from '../audiences/domain/usecases/get-next-set-audience.usecase';
import { ClaimStatusDisplayPipe } from 'src/app/core/pipe/claim-status-display.pipe';
import { GetPaginatedAllClaimsByConciliatorUseCase } from '../claims/domain/usecases/get-pagination-all-cliams-by-conciliator.usecase';
import { SuperAdminHomeComponent } from './presentation/components/home/SuperAdmin-home/SuperAdmin-home.component';
import { UsersComponent } from './presentation/components/users/users.component';
import { CompaniesComponent } from './presentation/components/companies/companies.component';
import { UsersTableComponent } from '../user/presentation/components/User-SuperAdmin/users-table.component';
import { GetPaginatedUserUseCase } from '../user/domain/usecases/get-paginated-user.usecase';
import { DeleteUserUseCase } from '../user/domain/usecases/delete-user.usecase';
import { UserUseCase } from '../user/domain/usecases/get-user.usecase';
import { GetPaginatedAllUserUseCase } from '../user/domain/usecases/get-pagination-all-user.usecase';
import { GetPaginationAudiencesofConciliatorUseCase } from '../audiences/domain/usecases/get-pagination-Conciliator-audiences.usecase';
import { newUserComponent } from '../user/presentation/components/New-User-SuperAdmin/newUser.component';
import { createUsersComponent } from './presentation/components/users/newUser/createUsers.component';
import { AudienceComponent } from '../audiences/presentation/components/audience/audience.component';
import { CreateComisionUserUseCase } from '../user/domain/usecases/create-user.usecase';
import { UpdateUserUseCase } from '../user/domain/usecases/update-user.usecase';
import { CompanyRemoteDataSource, CompanyRemoteDataSourceBase } from '../company/data/data-source/company-remote.datasource';
import { CompanyrepositoryBase } from '../company/domain/repositories/company-base.repository';
import { CompanyRepository } from '../company/data/repositories/company.repository';
import { CompanyTableComponent } from '../company/presentation/components/company-table/company-table.component';
import { IndividualCompanyComponent } from '../company/presentation/components/individual-company/individual-company.component';
import { CreateCompanyUseCase } from '../company/domain/usecases/create-company.usecase';
import { GetAllPaginationCompaniesUseCase } from '../company/domain/usecases/get-all-pagination-companies.usecase';
import { GetCompanyUseCase } from '../company/domain/usecases/get-company.usecase';
import { UpdateCompanyUseCase } from '../company/domain/usecases/update-company.usecase';
import { DeleteCompanyUseCase } from '../company/domain/usecases/delete-company.usecase';
import { MultiCompanyComponent } from '../company/presentation/components/multi-company/multi-company.component';
import { CreateMultiCompanyUseCase } from '../company/domain/usecases/create-multi-company.usecase';
import { SafeResourceUrlPipe } from 'src/app/core/pipe/safe-pdf.pipe';
import { MinuteComponent } from '../minutes/presentation/components/minute/minute.component';
import { MinuteRemoteDataSource, MinuteRemoteDataSourceBase } from '../minutes/data/data-source/minute-remote.datasource';
import { MinuteRepositoryBase } from '../minutes/domain/repositories/minute-base.repository';
import { MinuteRepository } from '../minutes/data/repositories/minute.repository';
import { CreateMinuteUseCase } from '../minutes/domain/usecases/create-minute.usecase';
import { GetMinutesByClaimIdUseCase } from '../minutes/domain/usecases/get-minutes-by-claim-id.usecase';
import { AudienceTableComponentNext } from '../audiences/presentation/components/audience-table-next/audience-table-next.component';
import {SidebarModule} from "primeng/sidebar";
import {NavComponent} from "../../core/components/nav/nav.component";
import {AvatarModule} from "primeng/avatar";
import {MenubarModule} from "primeng/menubar";
import {CardModule} from "primeng/card";
import {MegaMenuModule} from "primeng/megamenu";
import { GetNextAudienceOfClaimantUseCase } from '../audiences/domain/usecases/get-next-audience-of-claimant.usecase';
import { GetAllAudiencesByIdConciliadorPagination } from '../audiences/domain/usecases/getAllAudiencesByIdConciliadorPagination.usecase';
import { GetNextHomeAudienceOfConciliatorUseCase } from '../audiences/domain/usecases/get-next-audience-home-of-conciliator.usecase';
import {RippleModule} from "primeng/ripple";
import {InputIconModule} from "primeng/inputicon";
import {IconFieldModule} from "primeng/iconfield";
import { GetFileBaseUseCase } from '../claims/domain/usecases/get-file-base.usecase';
import { MultiSelectModule } from "primeng/multiselect"
import { GetAllNextAudiencesCase } from '../audiences/domain/usecases/get-all-next-audiences.usecase';
import { UpdateUserConfigComponent } from '../user/presentation/components/Update-User-Config/update-user-config.component';
import { UpdateUserAdminUseCase } from '../user/domain/usecases/update-user-admin.usecase';
import { ClaimFilterComponent } from '../claims/presentation/components/claim-filter/claim-filter.component';


@NgModule({
  declarations: [
    MainBaseComponent,
    HomeComponent,
    MyLaborClaims,
    ClaimsTableComponent,
    ConfigurationComponent,
    ResetPasswordComponent,
    ClaimViewComponent,
    ClaimComponent,
    ClaimantDetailsFormComponent,
    ClaimedDetailsFormComponent,
    ClaimDetailsFormComponent,
    AudienceTableComponent,
    ClaimsComisionTableComponent,
    ComisionHomeComponent,
    AudiencesComponent,
    SimpleRolHomeComponent,
    ClaimStatusDisplayPipe,
    SuperAdminHomeComponent,
    UsersComponent,
    CompaniesComponent,
    UsersTableComponent,
    newUserComponent,
    createUsersComponent,
    AudienceComponent,
    CompanyTableComponent,
    IndividualCompanyComponent,
    MultiCompanyComponent,
    SafeResourceUrlPipe,
    MinuteComponent,
    AudienceTableComponentNext,
    NavComponent,
    UpdateUserConfigComponent,
    ClaimFilterComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PrimengModule,
    MainRoutingModule,
    SharedModule,
    FormsModule,
    SidebarModule,
    AvatarModule,
    MenubarModule,
    CardModule,
    MegaMenuModule,
    RippleModule,
    InputIconModule,
    IconFieldModule,
    MultiSelectModule
  ],
  providers: [
    { provide: UserRemoteDataSourceBase, useClass: UserRemoteDataSource },
    { provide: UserRepositoryBase, useClass: UserRepository },
    GetMyDataUseCase,
    GetAllConciliatorsUseCase,
    CreateComisionUserUseCase,
    UpdateUserUseCase,
    UpdateUserAdminUseCase,
    {provide: ClaimRemoteDataSourceBase, useClass:ClaimRemoteDataSource},
    {provide: ClaimRepositoryBase, useClass:ClaimRepository},
    GetPaginatedClaimsUseCase,
    ClaimUseCase,
    UpdateClaimUseCase,
    DeleteClaimUseCase,
    CreateClaimUseCase,
    GetFileUseCase,
    GetFileBaseUseCase,
    GetPaginatedAllClaimsUseCase,
    GetPendingQuantityClaimsUseCase,
    UpdateClaimStatusUseCase,
    UpdateClaimConciliatorUseCase,
    GetPaginatedAllClaimsByConciliatorUseCase,
    {provide: AudienceRemoteDataSourceBase, useClass:AudienceRemoteDataSource},
    {provide: AudienceRepositoryBase, useClass:AudienceRepository},
    AudienceUseCase,
    GetPaginationAudiencesUseCase,
    CreateAudienceUseCase,
    DeleteAudienceUseCase,
    GetAllNextAudiencesCase,
    UpdateAudienceUseCase,
    GetNextAudienceOfConciliatorUseCase,
    GetNextAudienceOfClaimantUseCase,
    GetNextSetAudienceUseCase,
    GetPaginatedUserUseCase,
    DeleteUserUseCase,
    UserUseCase,
    GetPaginatedAllUserUseCase,
    GetPaginationAudiencesofConciliatorUseCase,
    {provide: CompanyRemoteDataSourceBase, useClass:CompanyRemoteDataSource},
    {provide: CompanyrepositoryBase, useClass:CompanyRepository},
    CreateCompanyUseCase,
    GetAllPaginationCompaniesUseCase,
    GetCompanyUseCase,
    UpdateCompanyUseCase,
    DeleteCompanyUseCase,
    CreateMultiCompanyUseCase,
    {provide: MinuteRemoteDataSourceBase, useClass:MinuteRemoteDataSource},
    {provide: MinuteRepositoryBase, useClass:MinuteRepository},
    CreateMinuteUseCase,
    GetMinutesByClaimIdUseCase,
    GetAllAudiencesByIdConciliadorPagination,
    GetNextHomeAudienceOfConciliatorUseCase
  ],
})
export class MainModule { }
