import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Failure } from 'src/app/core/utils/failure';
import { GetFileModel } from 'src/app/features/claims/data/models/get-file.model';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import { GetFileUseCase } from 'src/app/features/claims/domain/usecases/get-file.usecase';

interface Base64File {
  name: string;
  base64: string | ArrayBuffer | null;
  fileType: string;
}

interface ObjectClaim {
  id: number;
  name: string;
  isChecked: boolean;
  disabled: boolean;
}

interface Base64 {
  name: string;
  base64: string;
}

type FileType = 'image' | 'pdf' | 'doc';

@Component({
  selector: 'app-claim-details-form',
  templateUrl: './claim-details-form.component.html',
  styleUrls: ['./claim-details-form.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class ClaimDetailsFormComponent implements OnInit, OnChanges {
  @Output() claimDetails: EventEmitter<FormGroup> = new EventEmitter();
  @Output() stepIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeModal: EventEmitter<boolean> = new EventEmitter();
  @Output() forceCreation: EventEmitter<boolean> = new EventEmitter();
  @Input() formComplete!: number;

  @Input() claim?: ClaimEntity;


  activeMessages: Boolean = false;
  visibleCancel: boolean = false;

  uploadedFiles: string[] = [];
  isDragging: boolean = false;
  activeChecks: string[] = [];
  pdfFiles: any[] = [];
  disableFileUpload: boolean = false;
  progress: number = 0;
  checked: boolean = false;
  isDisabled245: boolean = false;
  isDisabled247: boolean = false;
  showLawyerInputs: boolean = false;
  public isEditMode: boolean = false;


  objectOfClaim: ObjectClaim[] = [
    { id: 1, name: 'COBRO DE SALARIOS', isChecked: false, disabled: false },
    { id: 2, name: 'DIFERENCIAS SALARIALES', isChecked: false, disabled: false },
    { id: 3, name: 'ACLARE SITUACIÓN LABORAL', isChecked: false, disabled: false },
    { id: 4, name: 'CESE DE LA DESVINCULACIÓN', isChecked: false, disabled: false },
    { id: 5, name: 'CESE DE MEDIDA DE ACCIÓN DIRECTA', isChecked: false, disabled: false },
    { id: 6, name: 'RETROTRAER EL CONFLICTO AL ESTADO ANTERIOR', isChecked: false, disabled: false },
    { id: 7, name: 'COBRO INDEMNIZACIÓN ART. 245 LCT', isChecked: false, disabled: false },
    { id: 8, name: 'COBRO INDEMNIZACION ART. 247 LCT', isChecked: false, disabled: false },
    { id: 9, name: 'ENCUADRE CONVENCIONAL', isChecked: false, disabled: false },
    { id: 10, name: 'INCUMPLIMIENTOS VARIOS', isChecked: false, disabled: false },
    { id: 11, name: ' INTERPRETACIÓN NORMATIVA CONVENCIONAL. ', isChecked: false, disabled: false },
    { id: 12, name: 'OTRO', isChecked: false, disabled: false },
  ];

  ownRight: any[] = [
    { id: 1, name: 'POR DERECHO PROPIO' },
    { id: 1, name: 'CON PATROCINIO LETRADO' },
  ];

  constructor(private formBuilder: FormBuilder,
    private getFileUseCase: GetFileUseCase) { }

  ngOnInit(): void {
    
    this.checkedObject();
    this.formClaimDetails.controls[
      'selecterObjectOfClaim'
    ].valueChanges.subscribe((selectedValue) => {
      this.activeMessages =
        selectedValue === 'COBRO INDEMNIZACIÓN ART. 245 LCT' ||
        selectedValue === 'COBRO INDEMNIZACION ART. 247 LCT';
    });

    this.formClaimDetails.controls['selectedOwnRight'].valueChanges.subscribe(
      (selectedValue) => {
        this.showLawyerInputs = selectedValue === 'CON PATROCINIO LETRADO';
        this.setValidatorsBasedOnRole();
      }
    );

    this.formClaimDetails.get('selecterObjectOfClaim')?.valueChanges.subscribe(value => {
      if (value) {
        this.formClaimDetails.get('textClaimDetails')?.enable();
      } else {
        this.formClaimDetails.get('textClaimDetails')?.disable();
      }
    });
  }

  checkedObject(){
    if(this.claim?.selecterObjectOfClaim){
      const selecterObjectOfClaim = this.claim.selecterObjectOfClaim;
      this.activeChecks = Array.isArray(selecterObjectOfClaim) ? selecterObjectOfClaim : [];    }
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key >= '0' && event.key <= '9') {
      event.preventDefault();
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['claim'] && changes['claim'].currentValue) {
      this.isEditMode = true;
      this.initializeFormWithClaimData();
      this.setValidatorsBasedOnRole();
    }

    if (this.formClaimDetails.controls['selectedOwnRight'].value === 'CON PATROCINIO LETRADO') {
      this.showLawyerInputs = true;
    }
  }

  formClaimDetails: FormGroup = this.formBuilder.group({
    selecterObjectOfClaim: [, [Validators.required]],
    confirmInformation: [],
    textClaimDetails: [, [Validators.required]],
    extraDocumentationPdf: [],
    selectedOwnRight: ['', [Validators.required]],
    lawyerFullName: [, [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/)]],
    lawyerRegistrationNumber: ['', [Validators.required, Validators.maxLength(10)]],
    lawyerEmail: [, [Validators.required, Validators.email, Validators.maxLength(100)]],
  })

  private async initializeFormWithClaimData(): Promise<void> {
    if (!this.claim) return;
    if (this.isEditMode) {
      this.formClaimDetails.patchValue({
        selecterObjectOfClaim: this.claim.selecterObjectOfClaim || '',
        confirmInformation: this.claim.confirmInformation || '',
        textClaimDetails: this.claim.textClaimDetails || '',
        extraDocumentationPdf: this.claim.extraDocumentationPdf || '',
        selectedOwnRight: this.claim.selectedOwnRight || '',
        lawyerFullName: this.claim.lawyerFullName || '',
        lawyerRegistrationNumber: this.claim.lawyerRegistrationNumber || '',
        lawyerEmail: this.claim.lawyerEmail || ''
      });
      await this.initializeFormWithSavedFiles();
    }
  }

  showDialogCancel() {
    this.visibleCancel = true;
  }

  closeDialog() {
    this.visibleCancel = false;
  }

  onDragOver(event: Event) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDropSuccess(event: any) {
    event.preventDefault();
    this.isDragging = false;
    this.handleFiles(event.dataTransfer.files);
  }

  onChange(event: any) {
    this.handleFiles(event.target.files);
  }

  onFileChange(files: FileList) {
    this.pdfFiles = Array.from(files);
  }

  private handleFiles(files: FileList) {
    if (files.length === 0) return;

    const remainingSlots = 2 - this.pdfFiles.length;
    if (remainingSlots <= 0) {
      this.disableFileUpload = true;
      return;
    }

    const filesToProcess = Math.min(files.length, remainingSlots);

    for (let i = 0; i < filesToProcess; i++) {
        const fileType = this.getFileType(files[i]);
        this.convertToBase64(files[i], fileType);
    }
  }

  private getFileType(file: File): FileType {
    const extension = file.name.split('.').pop()?.toLowerCase() || '';
    const fileTypes = this.getFileTypes();
    if (fileTypes.has(extension)) {
      return fileTypes.get(extension) as FileType;
    } else {
      throw new Error(`Unsupported file type: ${extension}`);
    }
  }

  getFileTypes(): Map<string, string> {
    return new Map<string, string>([
      ['pdf', 'pdf'],
      ['doc', 'doc'],
      ['docx', 'doc'],
      ['jpg', 'image'],
      ['jpeg', 'image'],
      ['png', 'image'],
      ['gif', 'image'],
      ['bmp', 'image'],
    ]);
  }

  convertToBase64(file: File, fileType: string) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString();
      if (base64String) {
        const base64File: Base64File = {
          name: file.name,
          base64: base64String,
          fileType,
        };
        this.pdfFiles.push(base64File);
        this.updateFormFiles();
        this.checkFileImageCount();
      }
    };
    reader.readAsDataURL(file);
  }

  updateFormFiles() {
    const fileBase64Array = this.pdfFiles.map((file) => file.base64);
    this.formClaimDetails.get("extraDocumentationPdf")?.setValue(fileBase64Array);
    this.formClaimDetails.get('extraDocumentationPdf')?.updateValueAndValidity();
  }

  deleteFile(index: number) {
    this.pdfFiles.splice(index, 1);
    const filePdfsBase64Array = this.pdfFiles.map((image) => image.base64);
    this.formClaimDetails.get("extraDocumentationPdf")?.setValue(filePdfsBase64Array.length ? filePdfsBase64Array : null);
    this.formClaimDetails.get("extraDocumentationPdf")?.markAsTouched();
    this.formClaimDetails.get("extraDocumentationPdf")?.markAsDirty();
    this.resetFileInput();
    this.checkFileImageCount();
  }

  private checkFileImageCount() {
    this.disableFileUpload = this.pdfFiles.length >= 2;
  }
  resetFileInput(): void {
    const inputFileElement = document.getElementById('inputFilePfd') as HTMLInputElement;
    if (inputFileElement) {
      inputFileElement.value = '';
      this.disableFileUpload = this.pdfFiles.length >= 2;
    }
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.formClaimDetails.get(controlName)?.hasError(errorType) &&
      this.formClaimDetails.get(controlName)?.touched
    );
  }

  nextStep() {
    
    if (this.formClaimDetails.invalid) return;
    const extraDocsControl = this.formClaimDetails.get('extraDocumentationPdf');
    if (!this.pdfFiles.length) {
      extraDocsControl?.setValue([]);
    }
    this.checkSponsorship();
    this.forceCreation.emit(true);
    this.claimDetails.emit(this.formClaimDetails.value);
  }
  
  checkSponsorship() {
    if (this.formClaimDetails.get('selectedOwnRight')?.value === 'POR DERECHO PROPIO') {
      this.formClaimDetails.get('lawyerFullName')?.setValue('');
      this.formClaimDetails.get('lawyerRegistrationNumber')?.setValue('');
      this.formClaimDetails.get('lawyerEmail')?.setValue('');
    }
  }
  
  cancel() {    
    this.claimDetails.emit(this.formClaimDetails.value);
    this.activeModal.emit(true)
  }

  back() {
    this.stepIndex.emit(1);
  }

  setValidatorsBasedOnRole() {
    if (this.formClaimDetails.get('selectedOwnRight')?.value === 'CON PATROCINIO LETRADO') {
      this.formClaimDetails.get('lawyerFullName')?.setValidators([Validators.required, Validators.minLength(2)]);
      this.formClaimDetails.get('lawyerRegistrationNumber')?.setValidators([Validators.required, Validators.maxLength(10)]);
      this.formClaimDetails.get('lawyerEmail')?.setValidators([Validators.required, Validators.email]);
    } else {
      this.formClaimDetails.get('lawyerFullName')?.clearValidators();
      this.formClaimDetails.get('lawyerRegistrationNumber')?.clearValidators();
      this.formClaimDetails.get('lawyerEmail')?.clearValidators();
    }
    this.formClaimDetails.get('lawyerFullName')?.updateValueAndValidity();
    this.formClaimDetails.get('lawyerRegistrationNumber')?.updateValueAndValidity();
    this.formClaimDetails.get('lawyerEmail')?.updateValueAndValidity();
  }

  private async initializeFormWithSavedFiles(): Promise<void> {
    const extraDocumentationPdfs = this.formClaimDetails.get("extraDocumentationPdf")?.value || [];

    for (let i = 0; i < extraDocumentationPdfs.length; i++) {
      let extraDocFiles = new GetFileModel(
        this.claim?.id!,
        this.claim?.extraDocumentationPdf?.[i]!
      );

      let bas64 = await this.getFile(extraDocFiles);
      const extraDocumentationPdf = extraDocumentationPdfs[i];

      const prefix = extraDocumentationPdf.includes('.png')
        ? 'data:image/png;base64,'
        : 'data:application/pdf;base64,';
      let file = prefix + bas64;
      let base64Objet: Base64 = {
        name: 'Documentacion extra' + (i + 1),
        base64: file
      }
      this.pdfFiles.push(base64Objet);
    }
    this.formClaimDetails.get("extraDocumentationPdf")?.setValue(this.pdfFiles);
  }

  async getFile(param: GetFileModel): Promise<string> {
    let result = await this.getFileUseCase.execute(param);
    if (result instanceof Failure) {
      return result.message;
    }
    return result;
  }

  onCheckboxChange() {
    if (this.checked) {
      this.objectOfClaim[7].disabled = true;
      this.objectOfClaim[6].disabled = true;
    } else {
      if(this.isDisabled247){
        this.objectOfClaim[7].disabled = false;
        this.objectOfClaim[6].disabled = true;
      }
      if(this.isDisabled245){
        this.objectOfClaim[7].disabled = true;
        this.objectOfClaim[6].disabled = false;
      }
    }
  }

  disableIndemnificationCheck(object: ObjectClaim): void {

    if(object.name === "COBRO INDEMNIZACIÓN ART. 245 LCT" && object.disabled == false) {
      this.objectOfClaim[7].disabled = !this.objectOfClaim[7].disabled;
      this.isDisabled245 = true;
      this.isDisabled247 = false;
    }
    if (object.name === "COBRO INDEMNIZACION ART. 247 LCT" && object.disabled == false ) {
      this.objectOfClaim[6].disabled = !this.objectOfClaim[6].disabled;
      this.isDisabled247 = true;
      this.isDisabled245 = false;
    }

    const result = this.activeChecks.find(x =>
      x === "COBRO INDEMNIZACION ART. 247 LCT" ||
      x === "COBRO INDEMNIZACIÓN ART. 245 LCT"
    );

    if (result) {
      this.activeMessages = true;
    } else {
      this.activeMessages = false;
    }
  }
}
