import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { CompanyEntity } from '../../../domain/entities/company.entity';
import { CompanyPagination } from '../../../domain/entities/company-pagination';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { Failure } from 'src/app/core/utils/failure';
import { CreateCompanyUseCase } from '../../../domain/usecases/create-company.usecase';
import { CompanyModel } from '../../../data/models/company.model';
import { GetCompanyUseCase } from '../../../domain/usecases/get-company.usecase';
import { MessageService } from 'primeng/api';
import { UpdateCompanyUseCase } from '../../../domain/usecases/update-company.usecase';
import { Location } from '@angular/common';

interface Localities {
  id: number;
  name: string;
}

@Component({
  selector: 'app-individual-company',
  templateUrl: './individual-company.component.html',
  styleUrls: ['./individual-company.component.css']
})
export class IndividualCompanyComponent implements OnInit {
  @Output() showMessage = new EventEmitter<{ severity: string, summary: string, detail: string }>();

  userId?: string;
  provinces: ProvinceEntity[] = [];
  localities: Localities[] = [];
  private AES = new AESEncryptDecryptService();
  isNewCompany: boolean = true;
  isEditMode: boolean = false;
  selectedCompanyId?: string | null;
  company?: CompanyEntity;

  defaultRows: number = 5;
  defaultPage: number = 0;
  public totalQuantity: number = 0;
  public first: number = 0;
  public itemsPerPage: number = 5;
  public amountRegistersPage = this.itemsPerPage;
  paginatedData: CompanyPagination | undefined = undefined;



  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private getProvince: GetProvinceUseCase,
    private getLocations: GetLocationsUseCase,
    private router: Router,
    private localStorageService: LocalStorageServiceBase,
    private createCompanyUseCase: CreateCompanyUseCase,
    private getCompanyUseCase: GetCompanyUseCase,
    private updateCompanyUseCase: UpdateCompanyUseCase,
    private messageService: MessageService,
    private location: Location,

    ) { }

    ngOnInit(): void {
      this.initializeComponent();      
    }
    
    async initializeComponent(): Promise<void> {      
      await this.loadProvinces();
    
      this.companyForm.get('province')?.valueChanges
      .subscribe((selectedProvince: any) => {
        const province = this.getProvinceIdByName(selectedProvince);
        if (province) {
          this.loadLocalities(province);
          this.companyForm.patchValue({ provincia: province }, { emitEvent: false });
        }
      });
    
      this.userId = this.AES.decrypt(this.localStorageService.loadStorageData('user_id'));
      if (this.router.url.includes('main/company/update')) {
        this.isNewCompany = false;
        this.isEditMode = true;
        this.selectedCompanyId = this.route.snapshot.paramMap.get('id');
        this.getCompany();
      }
    }
    

  private getProvinceIdByName(provinceName: string): string | undefined {
    const province = this.provinces.find(p => p.name === provinceName);

    return province ? province.id : undefined;
  }

  companyForm: FormGroup = this.formBuilder.group({
    companyName: ['', [Validators.required, Validators.maxLength(50)]],
    cuit: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
    phone: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(15), Validators.pattern(/^[0-9]*$/)]],
    province: ['', [Validators.required]],
    neighborhood: ['', [Validators.required]],
    street: ['', [Validators.required, Validators.maxLength(50)]],
    number: ['', [Validators.required, Validators.maxLength(5), Validators.pattern(/^[0-9]*$/)]],
    postalCode: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[0-9]*$/)]],
    floorApartment: ['', [Validators.maxLength(10)]],
    tower: ['', [Validators.maxLength(30)]],
    city: ['', Validators.required],
  });

  private initializeFormWithClaimData(): void {    
    if (!this.company) return;
    if (this.isEditMode) {      
      this.companyForm.patchValue({
        companyName: this.company.razon_social || '',
        cuit: this.company.cuit || '',
        email: this.company.email || '',
        phone: this.company.phone || '',
        street: this.company.street || '',
        number: this.company.number || '',
        floorApartment: this.company.floor_apartment || '',
        postalCode: this.company.postal_code || '',
        neighborhood: this.company.neighborhood || '',
        province: this.company.province || '',
        city: this.company.city || '',
        tower: this.company.tower || ''
      });
    }    
  }

  async getCompany(): Promise<void> {
    let result = await this.getCompanyUseCase.execute(this.selectedCompanyId!);
    if (result instanceof Failure) {
      return;
    }
    this.company = result;    
    this.initializeFormWithClaimData();
  }

  onSubmit(data: FormGroup) {
    if (this.isNewCompany) {
      this.createCompany(data);
    } else {
      this.updateCompany(data);
    }
  }

  async createCompany(data: FormGroup): Promise<void> {
    this.companyForm.patchValue(data);
    let result = await this.createCompanyUseCase.execute(this.createNewCompany());
    if(result instanceof Failure) {
      this.showMessage.emit({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.showMessage.emit({
      severity: 'success',
      summary: 'Empresa creada',
      detail: 'La empresa se creó exitosamente.',
    });
    setTimeout(() => {
      this.router.navigate(['/main/companies']);
    }, 1100);
  }


  async updateCompany(data: FormGroup): Promise<void> {
    this.companyForm.patchValue(data);
    let result = await this.updateCompanyUseCase.execute(this.createNewCompany());
    if(result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Empresa actualizada',
      detail: 'La empresa se actualizó exitosamente.',
    });
    setTimeout(() => {
      this.location.back();
    }, 1000);
  }


  async loadProvinces() {
    const result = await this.getProvince.execute();
    if (Array.isArray(result)) {
      this.provinces = result;
    }
  }

  async loadLocalities(provinceId: string) {
    const localities = await this.getLocations.execute(provinceId);
    if (Array.isArray(localities)) {
      this.localities = this.putIdToLocalitiesList(localities);
    }
  }


  putIdToLocalitiesList(list: LocationEntity[]): Localities[] {
    let listWithId: Localities[] = [];
    for (let i = 0; i < list.length; i++) {
      let city: Localities = {
        id: i,
        name: list[i].name
      };
      listWithId.push(city);
    }
    return listWithId;
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.companyForm.get(controlName)?.hasError(errorType) &&
      this.companyForm.get(controlName)?.touched
    );
  }

  cancel() {
    this.router.navigate(['/main/companies']);
  }

  createNewCompany(): CompanyModel {
    return new CompanyModel(
      this.companyForm.get('companyName')?.value,
      this.companyForm.get('cuit')?.value,
      this.companyForm.get('phone')?.value,
      this.companyForm.get('province')?.value,
      this.companyForm.get('city')?.value,
      this.companyForm.get('street')?.value,
      this.companyForm.get('number')?.value.toString(),
      this.companyForm.get('postalCode')?.value.toString(),
      this.companyForm.get('email')?.value,
      this.companyForm.get('neighborhood')?.value,
      this.companyForm.get('floorApartment')?.value,
      this.companyForm.get('tower')?.value,
      this.selectedCompanyId!,
    )
  }

}
