import { Injectable } from "@angular/core";
import { ProvinceRepositoryBase } from "../../domain/repositories/province-base.repository";
import { ProvinceRemoteDataSourceBase } from "../data-sources/province-remote.datasource";
import { ProvinceEntity } from "../../domain/entities/province.entity";
import { Failure, UnhandledFailure } from "src/app/core/utils/failure";
import { LocationEntity } from "../../domain/entities/location.entity";
import { Departament } from "src/app/features/user/presentation/components/New-User-SuperAdmin/newUser.component";

@Injectable()
export class ProvinceRepository extends ProvinceRepositoryBase {
    
    constructor(private provinceRepository: ProvinceRemoteDataSourceBase) {
        super()
    }

    override async getProvinces(): Promise<ProvinceEntity[] | Failure> {
        try {
            return await this.provinceRepository.getProvinces();
        } catch (error: any) {
            return new UnhandledFailure(error, 500);
        }
    }

    override async getLocationsByProvince(id: string): Promise< LocationEntity[] | Failure > {
        try {
            return await this.provinceRepository.getLocations(id);
        } catch (error: any) {
            return new UnhandledFailure(error, 500)
        }
    }

    override async getDepartments(): Promise<Departament[] | Failure> {
        try {
            return await this.provinceRepository.getDepartments();
        } catch (error: any) {
            return new UnhandledFailure(error, 500);
        }
    }

    override async getDepartmentsByIdConciliador(id: string): Promise<Departament[] | Failure> {
        try {
            return await this.provinceRepository.getDepartmentsByIdConciliador(id);
        } catch (error: any) {
            return new UnhandledFailure(error, 500);
        }
    }

    override async getDepartmentsById(id: string): Promise<Departament | Failure> {
        try {
            return await this.provinceRepository.getDepartmentsById(id);
        } catch (error: any) {
            return new UnhandledFailure(error, 500);
        }
    }
}