import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { UserRepositoryBase } from "../repositories/user-base.repository";
import { Injectable } from "@angular/core";
import { AdminUser } from "../entities/AdminUser.entity";

@Injectable()
export class UpdateUserAdminUseCase implements UseCase<AdminUser, string | Failure> {
    constructor(private UserRepository: UserRepositoryBase) { }

    async execute(param: AdminUser): Promise<string | Failure> {   
        return await this.UserRepository.updateUserAdmin(param);
    }
}
