import { Entity } from "src/app/core/utils/entity";

export abstract class MinutePdf extends Entity {
    constructor(
        public file_Acta: string[],
        public claim_id: string,
        public file_Acta_Name: string,
        public date: string
    ) {
        super();
    }
}
