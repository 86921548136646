import { Entity } from "src/app/core/utils/entity";

export abstract class AudienceEntity extends Entity {
    constructor(
        public date: string,
        public time: string,
        public audienceTitle: string,
        public claimetId: string,
        public claimedId: string,
        public claimId: string,
        public claimetName?: string,
        public claimetLastname?: string,
        public claimedNameLastNameOrBusinessName?: string,
        public conciliator?: string,
        public proceedings?: string,
        public numberOfClaim?: string,
        public audienceNumber?: string,
        public conciliatorId?: string,
        public claimetEmail?: string,
        public claimedEmail?: string,
        public id?: string,
        public link_google_meet?: string,
        public expediente_claim?: string,
    ) {
        super();
    }
}
