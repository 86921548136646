<div class="card flex justify-content-center w-full relative">
    <p-toast />
    <div class="spinner-overlay" *ngIf="isLoading">
        <div class="spinner-container">
          <h2 class="spinner-header">Generando el reclamo</h2>
          <app-spinner></app-spinner>
        </div>
      </div>      
    <p-confirmDialog #cd>
        <ng-template pTemplate="headless" let-message class="congirmDialog">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <span class="labelTitle1">
                    {{ message.header }}
                </span>
                <div class=" flex justify-content-center align-items-center gap-2">
                    <i class="pi pi-exclamation-circle"></i>
                    <p class="text-base">{{ message.message }}</p>
                </div>
                <div class="flex flex-row justify-content-center align-items-center gap-2 mt-3 ">
                    <ng-container *ngIf="isNewClaim" class="col-12">
                        <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar col-6" style="border: 1px solid #263245;
                            background-color: white;
                            color: #263245;
                            font-size: 0.8rem;">
                        </button>
                        <button pButton label="¡Entendido!" (click)="cd.accept()" class="btn-siguiente col-6"
                            style="
                            background-color: #263245;
                            border-color: #263245;
                            font-size: 0.8rem;
                            ">
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!isNewClaim" class="col-12">
                        <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar col-6" style="border: 1px solid #263245;
                            background-color: white;
                            color: #263245;
                            font-size: 0.8rem;">
                        </button>
                        <button pButton label="Aceptar" (click)="cd.accept()" class="btn-siguiente col-6" style="
                            background-color: #263245;
                            border-color: #263245;
                            font-size: 0.8rem;
                            ">
                        </button>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </p-confirmDialog>
</div>
<div class="w-full relative p-4">
    <div class="container-top">
        <i routerLink="/main/claims" class="pi pi-angle-left"></i>
        <p><a href="" routerLink="/main/claims">Mis reclamos laborales</a></p>
        <p>/</p>
        <p><a class="font-bold">{{ isNewClaim ? 'Nuevo reclamo laboral' : 'Editar reclamo laboral' }}</a></p>
    </div>
    <div class="container-title">
      <p *ngIf="isNewClaim" class="title">Nuevo reclamo laboral</p>
      <p *ngIf="!isNewClaim" class="title">Editar reclamo laboral</p>
      <p class="description">
          Complete los datos requeridos para {{ isNewClaim ? 'realizar el reclamo laboral' : 'editar el reclamo laboral' }}
      </p>
    </div>

    <div class="w-full">
        <p-tabView [(activeIndex)]="activeIndex" class="w-full disabled-tab">
            <p-tabPanel class="w-full">
                <ng-template pTemplate="header">
                    <div class="hidden md:block">Datos del reclamante</div>
                    <div class="block md:hidden">
                        <i class="pi pi-user text-base" pTooltip="Datos del reclamante" tooltipPosition="right"></i>
                    </div>
                </ng-template>
                <app-claimant-details-form [claim]="claim" [userData]="userData" (claimantDetailsForm)="receiveData($event)"
                    (stepIndex)="activeStep($event)" (activeModal)="showCancelModal($event)"></app-claimant-details-form>
            </p-tabPanel>

            <p-tabPanel class="w-full">
                <ng-template pTemplate="header">
                    <div class="hidden md:block">Datos del reclamado</div>
                    <div class="block md:hidden">
                        <i class="pi pi-users text-base" pTooltip="Datos del reclamado" tooltipPosition="right"></i>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-claimed-details-form [claim]="claim" (claimed)="receiveData($event)"
                        (stepIndex)="activeStep($event)" (activeModal)="showCancelModal($event)"></app-claimed-details-form>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel class="w-full">
                <ng-template pTemplate="header">
                    <div class="hidden md:block">Detalles del reclamo</div>
                    <div class="block md:hidden">
                        <i class="pi pi-file text-base" pTooltip="Detalles del reclamo" tooltipPosition="right"></i>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-claim-details-form [claim]="claim" (claimDetails)="onSubmit($event)" (forceCreation)="onForceCreation($event)"
                        (stepIndex)="activeStep($event)" (activeModal)="showCancelModal($event)"
                        [formComplete]="formComplete"></app-claim-details-form>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>

</div>
