import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import { CreateAudienceUseCase } from '../../../domain/usecases/create-audience.usecase';
import { Failure } from 'src/app/core/utils/failure';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { Router } from '@angular/router';
import { CreateAudienceModel } from '../../../data/models/create-audience.model';
import { CalendarTranslationService } from 'src/app/core/services/translate-calendar.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.css'],
  providers: [MessageService, Toast, ConfirmationService]
})
export class AudienceComponent {
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();
  @Output() createAudienceOk: EventEmitter<boolean> = new EventEmitter();
  @Output() showMessage = new EventEmitter<{ severity: string, summary: string, detail: string }>();
  @Input() claim?: ClaimEntity;

  time: Date[] | undefined;
  private AES = new AESEncryptDecryptService();
  minDate: Date = new Date();
  isButtonClicked: boolean = false;
  recargada: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageServiceBase,
    private createAudienceUseCase: CreateAudienceUseCase,
    private messageService: MessageService,
    private router: Router,
    private calendarTranslationService: CalendarTranslationService,
    private location: Location
  ) { }

  ngOnChanges() {
    if (this.claim) {
      this.initializeFormWithClaimData();
    }
    this.calendarTranslationService.configureCalendarTranslations();
  }

  audienceForm: FormGroup = this.formBuilder.group({
    date: [, [Validators.required]],
    time: [, [Validators.required]],
    audienceTitle: [, [Validators.required, Validators.maxLength(30)]],
    conciliatorFullName: [, [Validators.required]],
    linkGoogleMeet: [, [Validators.required]],
    linkGoogleCalendar: []
  })

  cancel() {
    this.audienceForm.reset();
    this.closePopup.emit(true);
  }

  initializeFormWithClaimData(): void {
    this.audienceForm.patchValue({
      conciliatorFullName: this.claim?.conciliatorFullName || 'No asignado',
    });
  }

  async createAudience(): Promise<void> {
    this.isButtonClicked = true;
    this.audienceForm.disable();

    const result = await this.createAudienceUseCase.execute(this.createNewAudience());
    if (result instanceof Failure) {
      this.showMessage.emit({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      this.isButtonClicked = false;
      this.audienceForm.enable();
      return;
    }
    this.isButtonClicked = false;

    this.showMessage.emit({
      severity: 'success',
      summary: 'Audiencia creada',
      detail: 'La audiencia se creó exitosamente.',
    });
    setTimeout(() => {
      if (!this.recargada) {
        this.recargada = true;
        this.closePopup;
        window.location.reload();
      }
    }, 1000);
    this.createAudienceOk.emit(true);
    this.cancel();
  }

  createNewAudience(): CreateAudienceModel {
    const selectedDate = this.audienceForm.get('date')?.value;
    const selectedTime = this.audienceForm.get('time')?.value;
    const fullDate = new Date(selectedDate);
    fullDate.setHours(selectedTime.getHours() - 3);
    fullDate.setMinutes(selectedTime.getMinutes());
    fullDate.setSeconds(0);

    return new CreateAudienceModel(
      fullDate.toISOString(),
      new Date(selectedTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      this.audienceForm.get('audienceTitle')?.value,
      this.claim?.user_id!,
      this.claim?.id!,
      this.audienceForm.get('linkGoogleMeet')?.value
    );
  }


  hasErrors(controlName: string, errorType: string) {
    return (
      this.audienceForm.get(controlName)?.hasError(errorType) &&
      this.audienceForm.get(controlName)?.touched
    );
  }

  openGoogleCalendar() {
    const title = 'Nombre del Evento';
    const description = 'Descripción del evento';
    const location = 'Ubicación del evento';
    const startDateObj = new Date();
    const endDateObj = new Date(startDateObj.getTime() + 60 * 60 * 1000);
    const startDate = this.formatDateForGoogleCalendar(startDateObj);
    const endDate = this.formatDateForGoogleCalendar(endDateObj);
    const calendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(title)}&dates=${startDate}/${endDate}&details=${encodeURIComponent(description)}&location=${encodeURIComponent(location)}&sf=true&output=xml`;
    window.open(calendarUrl, '_blank');
  }

  formatDateForGoogleCalendar(date: Date): string {
    return date.toISOString().replace(/-|:|\.\d+/g, '');
  }

}


