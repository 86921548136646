import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Rol } from 'src/app/core/interfaces/user.interface';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';

interface Localities {
  id: number;
  name: string;
}

@Component({
  selector: 'app-step-location',
  templateUrl: './step-location.component.html',
  styleUrls: ['./step-location.component.css']
})
export class StepLocationComponent implements OnInit {

  @Input() initialData: any = {};
  @Output() location: EventEmitter<FormGroup> = new EventEmitter();
  @Output() back: EventEmitter<number> = new EventEmitter();
  @Input() selectedRol!: Rol;

  registerFormLocation: FormGroup = this.formBuilder.group({
    province: [, Validators.required],
    city: [, Validators.required],
    street: [, [Validators.required, Validators.maxLength(50)]],
    number: [, [Validators.required, Validators.maxLength(5)]],
    floor_apartment: [, Validators.maxLength(10)],
    tower: [, Validators.maxLength(30)],
    postal_code: [, [Validators.required, Validators.maxLength(10)]]
  })

  provinces: ProvinceEntity[] = [];
  localities: Localities[] = [];

  async ngOnInit() {
    try {
      await this.loadProvinces();
      await this.initializeForm();
      this.setupProvinceChangeListener();
    } catch (error) {
      console.error('Initialization error:', error);
    }
  }

  constructor(private formBuilder: FormBuilder, private getProvince: GetProvinceUseCase, private getLocations: GetLocationsUseCase) { }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();

      if (Array.isArray(result)) {
        this.provinces = result;
      }

    } catch (error) {
      console.error('Error fetching provinces', error);
    }
  }

  async loadLocalities(provinceId: string) {
    const localities = await this.getLocations.execute(provinceId);
    if (Array.isArray(localities)) {
      this.localities = this.putIdToLocalitiesList(localities);
    }
  }

  putIdToLocalitiesList(list: LocationEntity[]): Localities[] {
    let listWithId: Localities[] = [];
    for (let i = 0; i < list.length; i++) {
      let city: Localities = {
        id: i,
        name: list[i].name
      };
      listWithId.push(city);
    }
    return listWithId;
  }

  nextStep() {
    if (this.registerFormLocation.invalid) return;
    this.location.emit(this.registerFormLocation.value);
  }

  previusStep() {
    this.back.emit(1);
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.registerFormLocation.get(controlName)?.hasError(errorType) &&
      this.registerFormLocation.get(controlName)?.touched
    );
  }

  async initializeForm() {
    if (this.initialData.province) {
      this.registerFormLocation.patchValue(this.initialData);
  
      const initialProvince = this.provinces.find(p => p.name === this.initialData.province);
      if (initialProvince) {
        this.registerFormLocation.get('province')?.setValue(initialProvince);
  
        await this.loadLocalities(initialProvince.name);
  
        const initialCity = this.localities.find(l => l.name === this.initialData.city);
        if (initialCity) {
          this.registerFormLocation.get('city')?.setValue(initialCity);
        }
      }
    }
  }

  setupProvinceChangeListener() {
    this.registerFormLocation.get('province')?.valueChanges.subscribe((selectedProvince: any) => {
      if (selectedProvince) {
        this.loadLocalities(selectedProvince);
        this.registerFormLocation.get('city')?.setValue(null);
      }
    });
  }

}
