import { Component, OnInit } from '@angular/core';
import { UserData } from '../../../features/user/domain/entities/user-data.entity';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../../../features/user/services/user.service';
import { GetMyDataUseCase } from '../../../features/user/domain/usecases/get-my-data.usecase';
import { Failure } from '../../utils/failure';
import { UserRol } from '../../enums/user-rol.enum';
import { MegaMenuItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
  providers: [ConfirmationService]
})
export class NavComponent implements OnInit {
  sidebarVisible = false;
  userData?: UserData;
  userRol?: string;
  claimButtonText = 'Mis reclamos laborales';
  commisionRol = false;
  isSuperAdmin = false;
  private routerSubscription: Subscription | undefined;
  currentRoute = '';
  items: MegaMenuItem[] | undefined;

  constructor(
    private router: Router,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private getMyDataUseCase: GetMyDataUseCase
  ) {}

  ngOnInit(): void {
    this.initializeUserData();

    this.currentRoute = this.router.url;
    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
      this.cargarItemsDelMenu();
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private async initializeUserData(): Promise<void> {
    await this.getMe();
  }

  private async getMe(): Promise<void> {
    const result = await this.getMyDataUseCase.execute();
    if (result instanceof Failure) {
      return;
    }
    this.userService.userData = result;
    this.userService.userData$.next(result);
    this.userService.userRol$.next(result.rol);
    this.userData = result;
    this.userRol = result.rol;
    this.setClaimButtonText();
    this.checkSuperAdminRole();
    this.cargarItemsDelMenu();
  }

  private setClaimButtonText(): void {
    const allowedRoles = [
      UserRol.ADMINISTRATOR,
      UserRol.COMISSION,
      UserRol.COMISSION_MEMBER,
      UserRol.CONCILIATOR,
    ];

    if (
      this.userData?.rol &&
      allowedRoles.includes(this.userData.rol as UserRol)
    ) {
      this.claimButtonText = 'Reclamos laborales';
      this.commisionRol = true;
    } else {
      this.claimButtonText = 'Reclamos Laborales';
      this.commisionRol = false;
    }
  }

  private checkSuperAdminRole(): void {
    const allowedRole = [UserRol.SUPER_ADMIN];
    if (
      this.userData?.rol &&
      allowedRole.includes(this.userData.rol as UserRol)
    ) {
      this.isSuperAdmin = true;
    } else {
      this.isSuperAdmin = false;
    }
  }

  logout(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.commisionRol = false;
    this.isSuperAdmin = false;
    this.router.navigate(['/auth/login']);
  }

showLogoutConfirm() {
  this.confirmationService.confirm({
    message: '¿Está seguro que desea cerrar sesión?',
    header: 'Confirmar Cierre de Sesión',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      this.logout();
    },
    reject: () => {
      // Se deja asi para cerrar el modal simplemente
    },
    acceptLabel: 'Continuar',
    rejectLabel: 'Cancelar',
    acceptButtonStyleClass: 'p-button-primary custom-continue-btn',
    rejectButtonStyleClass: 'p-button-secondary custom-cancel-btn'
  });
}

  cargarItemsDelMenu() {
    this.items = [
      {
        label: 'Menu',
        icon: 'pi pi-bars',
        command: () => (this.sidebarVisible = true),
        style: { color: '#007bff', fontWeight: 'bold' },
        styleClass: 'mr-2',
      },
      {
        label: 'Inicio',
        icon: 'pi pi-home',
        command: () => this.goToSelect('/main/home'),
        style: { color: '#007bff', fontWeight: 'bold' },
        styleClass:
          this.currentRoute == '/main/home'
            ? 'bg-gray-100 border-round mr-2'
            : 'border-round mr-2',
      },
      {
        label: `${this.userData?.name} ${this.userData?.lastName} - ${this.userData?.rol}`,
        icon: 'pi pi-user',
        command: () => this.goToSelect('/main/configuration'),
        style: { color: '#007bff', fontWeight: 'bold' },
        styleClass:
          this.currentRoute == '/main/configuration'
            ? 'bg-gray-100 border-round mr-2'
            : 'border-round mr-2',
      },
    ];
  }

  goToSelect(link: string) {
    this.router.navigate([link]);
    this.sidebarVisible = false;
  }
}
