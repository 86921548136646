import { Entity } from "src/app/core/utils/entity";

export abstract class AdminUser extends Entity {
    constructor(
      public rol: string,
      public name: string,
      public last_name: string,
      public cuil: string,
      public phone: string,
      public dni: string,
      public province: string,
      public city: string,
      public street: string,
      public number: number | string,
      public floor_apartment: string,
      public postal_code: number | string,
      public email: string,
      public tower: string,
      public id?: string,
      public gender?: string,
      public birth_date?: string,
      public civil_status?: string,
      public is_conciliator?: boolean,
      public is_comision?: boolean,
      public is_administrator?: boolean,
    ) {
      super();
    }
  }