import { ClaimData } from "../../domain/entities/claim-data.entity";

export class ClaimDataModel extends ClaimData {

    override toJson(): Map<string, any> {
      let json = new Map<string, any>();
      json.set('id', this.id);
      json.set('user_id', this.claimentId);
      json.set('name', this.claimentName);
      json.set('last_name', this.claimentLastName);
      json.set('cuil', this.claimentCuil);
      json.set('start_date', this.startDate);
      json.set('status', this.status);
      json.set('proceedings', this.proceedings);
      json.set('claimed_id', this.claimedId);
      json.set('business_name_claimed', this.nameLastNameOrBusinessNameClaimed);
      json.set('claimed_cuil', this.claimedCuil);
      json.set('business_name_claimant', this.nameLastNameOrBusinessNameClaimet);
      json.set('conciliator_id', this.conciliatorId);
      json.set('conciliator_full_name ', this.conciliatorFullName);
      json.set('number_of_claim', this.number_of_claim);
      json.set('expediente_claim', this.expediente_claim);
      return json;
    }

    static fromJson(data: Map<string, any>): ClaimData {
      return new ClaimDataModel(
        data.get('id'),
        data.get('user_id'),
        data.get('name'),
        data.get('last_name'),
        data.get('cuil'),
        data.get('start_date'),
        data.get('status'),
        data.get('proceedings'),
        data.get('claimed_id'),
        data.get('business_name_claimed'),
        data.get('claimed_cuil'),
        data.get('business_name_claimant'),
        data.get('conciliator_id'),
        data.get('conciliator_full_name'),
        data.get('number_of_claim'),
        data.get('expediente_claim'),
      );
    }
  }
