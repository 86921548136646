
<div *ngIf="isLoading" class="spinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
<form [formGroup]="registerForm">

    <app-step-rol *ngIf="formStep === 1" (rol)="reciveData($event)"></app-step-rol>

    <ng-container *ngIf="selectedRol === 'Trabajador'">

        <app-step-personal-information class="flex justify-content-center marginBox" *ngIf="formStep === 2"
            [initialData]="registerForm.value" (personales)="reciveData($event)" (back)="previousStep($event)"
            [selectedRol]="registerForm.get('rol')?.value"></app-step-personal-information>

        <app-step-location class="flex justify-content-center marginBox" *ngIf="formStep === 3" [initialData]="registerForm.value"
            (location)="reciveData($event)" (back)="previousStep($event)"
            [selectedRol]="registerForm.get('rol')?.value"></app-step-location>

        <app-step-mail-password class="flex justify-content-center marginBox" *ngIf="formStep === 4"
            [initialData]="registerForm.value" (emailPassword)="register($event)" (back)="previousStep($event)"
            [selectedRol]="registerForm.get('rol')?.value"></app-step-mail-password>

        <app-step-mail-validation class="flex justify-content-center marginBox" *ngIf="formStep === 5"
            [selectedRol]="registerForm.get('rol')?.value"></app-step-mail-validation>
        <ng-container *ngIf="selectedRol">
            <ng-container *ngIf="isStepValid()">
                <div class="stepper">
                <div *ngFor="let step of getSteps(); let i = index"
                        [class.step]="true"
                        [class.active]="formStep === step">
                </div>
                </div>
            </ng-container>
            </ng-container>
    </ng-container>

    <ng-container *ngIf="selectedRol === 'Empleador'">
        <app-typeResponsibility class="flex justify-content-center" *ngIf="formStep === 2"
        [initialData]="registerForm.value"
            [selectedRol]="registerForm.get('rol')?.value" (act)="reciveData($event)"
            (back)="previousStep($event)"></app-typeResponsibility>

        <ng-container *ngIf="this.registerForm.get('type_responsibility')?.value == 'Actúo en nombre propio'">

            <app-step-company-cuil class="flex justify-content-center" *ngIf="formStep === 3"
                [initialData]="registerForm.value" (company)="reciveData($event)" (back)="previousStep($event)"
                [selectedRol]="registerForm.get('rol')?.value"></app-step-company-cuil>
            <app-step-location class="flex justify-content-center" *ngIf="formStep === 4"
                [initialData]="registerForm.value" (location)="reciveData($event)" (back)="previousStep($event)"
                [selectedRol]="registerForm.get('rol')?.value"></app-step-location>
            <app-step-mail-password class="flex justify-content-center" *ngIf="formStep === 5"
                [initialData]="registerForm.value" (emailPassword)="register($event)" (back)="previousStep($event)"
                [selectedRol]="registerForm.get('rol')?.value"></app-step-mail-password>
            <app-step-mail-validation class="flex justify-content-center" *ngIf="formStep === 6"
                [selectedRol]="registerForm.get('rol')?.value"></app-step-mail-validation>
            <ng-container *ngIf="selectedRol">
                <ng-container *ngIf="isStepValid()">
                    <div class="stepper">
                    <div *ngFor="let step of getSteps(); let i = index"
                            [class.step]="true"
                            [class.active]="formStep === step">
                    </div>
                    </div>
                </ng-container>
                </ng-container>
        </ng-container>

        <ng-container  *ngIf="this.registerForm.get('type_responsibility')?.value == 'Soy representante de la Empresa'" >

            <app-step-company-cuil class="flex justify-content-center" *ngIf="formStep === 3" [initialData]="registerForm.value" (company)="reciveData($event)"
            (back)="previousStep($event)" [selectedRol]="registerForm.get('rol')?.value"></app-step-company-cuil>
            <app-step-location class="flex justify-content-center" *ngIf="formStep === 4" [initialData]="registerForm.value" (location)="reciveData($event)"
            (back)="previousStep($event)" [selectedRol]="registerForm.get('rol')?.value"></app-step-location>
            <app-step-personal-information  class="flex justify-content-center" *ngIf="formStep === 5" [initialData]="registerForm.value"
            (personales)="reciveData($event)" (back)="previousStep($event)" [selectedRol]="registerForm.get('rol')?.value"></app-step-personal-information>
            <app-step-mail-password class="flex justify-content-center" *ngIf="formStep === 6" [initialData]="registerForm.value" (emailPassword)="register($event)"
                    (back)="previousStep($event)" [selectedRol]="registerForm.get('rol')?.value"></app-step-mail-password>
            <app-step-mail-validation class="flex justify-content-center" *ngIf="formStep === 7"></app-step-mail-validation>
            <ng-container *ngIf="selectedRol">
                <ng-container *ngIf="isStepValid()">
                    <div class="stepper">
                        <div *ngFor="let step of getSteps(); let i = index"
                                [class.step]="true"
                                [class.active]="formStep === step">
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="selectedRol === 'Representante gremial'">

        <app-typeResponsibility class="flex justify-content-center marginBoxRep" *ngIf="formStep === 2"
        [initialData]="registerForm.value"
            [selectedRol]="registerForm.get('rol')?.value" (act)="reciveData($event)"
            (back)="previousStep($event)"></app-typeResponsibility>


        <app-step-personal-information class="flex justify-content-center marginBox" *ngIf="formStep === 3"
            [initialData]="registerForm.value" (personales)="reciveData($event)"
            (back)="previousStep($event)"></app-step-personal-information>

        <app-step-location class="flex justify-content-center marginBox" *ngIf="formStep === 4" [initialData]="registerForm.value"
            (location)="reciveData($event)" (back)="previousStep($event)"></app-step-location>

        <app-step-two-inputs class="flex justify-content-center marginBox" *ngIf="formStep === 5" [selectedRol]="selectedRol"
            [selectedeDoYouAct]="selectedTypeResponsibility" [initialData]="registerForm.value"
            (data)="reciveData($event)" (back)="previousStep($event)"></app-step-two-inputs>

        <app-step-mail-password class="flex justify-content-center marginBox" *ngIf="formStep === 6"
            [initialData]="registerForm.value" (emailPassword)="register($event)"
            (back)="previousStep($event)"></app-step-mail-password>

        <app-step-mail-validation class="flex justify-content-center marginBox" *ngIf="formStep === 7"
            [selectedRol]="registerForm.get('rol')?.value"></app-step-mail-validation>
            <ng-container *ngIf="selectedRol">
                <ng-container *ngIf="isStepValid()">
                  <div class="stepper">
                    <div *ngFor="let step of getSteps(); let i = index"
                         [class.step]="true"
                         [class.active]="formStep === step">
                    </div>
                  </div>
                </ng-container>
              </ng-container>
    </ng-container>


</form>

<p-toast></p-toast>
