import { Failure } from "src/app/core/utils/failure";
import { ProvinceEntity } from "src/app/core/services/location/domain/entities/province.entity";
import { LocationEntity } from "src/app/core/services/location/domain/entities/location.entity";
import { Departament } from "src/app/features/user/presentation/components/New-User-SuperAdmin/newUser.component";

export abstract class ProvinceRepositoryBase {
    abstract getProvinces(): Promise<ProvinceEntity[] | Failure>;
    abstract getLocationsByProvince(id: string): Promise<LocationEntity[] | Failure>;
    abstract getDepartments(): Promise<Departament[] | Failure>;
    abstract getDepartmentsByIdConciliador(id: string): Promise<Departament[] | Failure>;
    abstract getDepartmentsById(id: string): Promise<Departament | Failure>;
}