<section>
  <!-- empty claims -->
  <div *ngIf="!userClaims">
    <ng-container>
      <div class="emptyAudienciaText">
        <img id="image" src="../../../../../../assets/img/main/file-searching.svg" alt="imagen de archivo"/>
        <p>Ud. no ha generado ningún reclamo <br> o no tiene fecha de audiencia fijada</p>
      </div>
    </ng-container>
  </div>
  <!-- delete claim -->
  <p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>

      <div class="flex flex-column align-items-center justify-content-center p-5 surface-overlay border-round ">
              <span class="col-12 font-semibold text-xl ">
                  {{ message.header }}
              </span>
        <div class="col-12 flex align-items-center justify-content-center gap-3">
          <i class="pi pi-exclamation-circle text-lg"></i>
          <p class="">{{ message.message }}</p>
        </div>
        <div class="col-12 flex gap-3 justify-content-end">
          <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar" style="border: 1px solid #263245;
                  background-color: white;
                  color: #263245;
                  font-size: 0.8rem;">
          </button>
          <button pButton label="Eliminar reclamo" (click)="cd.accept()" class="delete btn-siguiente" style="
                      background-color: #C93B3B;
                      border-color: #C93B3B;
                      font-size: 0.8rem;">
          </button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>


  <!-- claim table -->
  <div *ngIf="userClaims">

    <div *ngIf="!homeView" class="flex align-items-center gap-4 col-12 md:col-4 p-input-icon-left mb-3">
      <p-iconField iconPosition="left">
        <p-inputIcon>
          <i class="pi pi-search"></i>
        </p-inputIcon>
        <input #inputElement
               pInputText
               type="text"
               placeholder="Buscar por Reclamado, Número de Reclamo, Fecha de inicio, Estado "
               (input)="dtClaims.filterGlobal(inputElement.value, 'contains')"
        />
      </p-iconField>
      <div>
        <i (click)="toogleFilter()" class="pi pi-sliders-h icon-filter"></i>
            <app-claim-filter class="modal-content" [showFilter]="showFilter" />
      </div>
    </div>

    <p-table #dtClaims
             (onLazyLoad)="loadRecords($event)"
             [value]="userClaims"
             currentPageReportTemplate="{first}-{last} resultados | Total {totalRecords}"
             [tableStyle]="{ 'min-width': '40rem' }"
             [globalFilterFields]="['nameLastNameOrBusinessNameClaimed', 'expediente_claim', 'startDate', 'status']">
      <ng-template pTemplate="header">
        <tr class="font-bold text-xs sm:text-md">
          <th>Reclamado</th>
          <th>Número de Reclamo</th>
          <th>Fecha de inicio</th>
          <th class="text-center">Estado</th>
          <th class="text-center"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-claim>
        <tr class="table-row text-xs sm:text-sm">
          <td>{{ claim.nameLastNameOrBusinessNameClaimed}}</td>
          <td>{{ claim.number_of_claim }}</td>
          <td>{{ claim.startDate | date: 'dd/MM/yyyy' }}</td>
          <td class="text-center">
            <p [ngClass]="getStatusClass(claim.status)">{{ claim.status | claimStatusDisplay }}</p>
          </td>
          <td class="text-center">
            <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
            <button type="button" pButton icon="pi pi-ellipsis-v"
              (click)="selectMenuItemsByRol(claim.status); menu.toggle($event); selectClaim(claim.id)"
              class="p-button-rounded p-button-text" style="width: 19% !important;"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-paginator *ngIf="!homeView" (onPageChange)="changePage($event)" [first]="first" [rows]="defaultRows"
      [totalRecords]="totalQuantity" [rowsPerPageOptions]="[5, 10, 25, 50]">
    </p-paginator>
  </div>
</section>
