import { ClaimEntity } from "../../domain/entities/claim.entity";

export class ClaimModel extends ClaimEntity {

    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('department_id', this.department_id);
        json.set('last_name', this.claimetLastname);
        json.set('name', this.claimetName);
        json.set('cuil', this.claimetCuil);
        json.set('email', this.claimetEmail);
        json.set('phone', this.claimetPhone);
        json.set('province', this.claimetProvince);
        json.set('city', this.claimetCity);
        json.set('neighborhood', this.claimetNeighborhood);
        json.set('street', this.claimetStreet);
        json.set('number', this.claimetNumber);
        json.set('postal_code', this.claimetPostalCode);
        json.set('selected_type_of_job', this.claimetSelectedTypeOfJob);
        json.set('workday', this.claimetWorkday);
        json.set('gross_remuneration', this.claimetGrossRemuneration);
        json.set('type_remuneration', this.claimetTypeRemuneration);
        json.set('date_admission', this.claimetDateAdmission);
        json.set('number_agreement', this.claimetNumberAgreement);
        json.set('year', this.claimetYear);
        json.set('category', this.claimetCategory);
        json.set('current_employment_status', this.claimetCurrentEmploymentStatus);
        json.set('file_dni', this.claimetFileDni);
        json.set('file_paycheck', this.claimetFilePaycheck);
        json.set('type_of_person_claimed', this.claimedTypeOfPerson);
        json.set('business_name_claimed', this.claimedNameLastNameOrBusinessName);
        json.set('claimed_cuil', this.claimedCuil);
        json.set('claimed_email', this.claimedEmail);
        json.set('claimed_phone', this.claimedPhone);
        json.set('claimed_street', this.claimedStreet);
        json.set('claimed_number', this.claimedNumber);
        json.set('claimed_postal_code', this.claimedPostalCode);
        json.set('claimed_neighborhood', this.claimedNeighborhood);
        json.set('claimed_province', this.claimedProvince);
        json.set('claimed_city', this.claimedCity);
        json.set('object_of_claim', this.selecterObjectOfClaim);
        json.set('text_claim_details', this.textClaimDetails);
        json.set('extra_documentation', this.extraDocumentationPdf);
        json.set('selected_own_right', this.selectedOwnRight);
        json.set('full_name_lawyer', this.lawyerFullName);
        json.set('registration_number', this.lawyerRegistrationNumber);
        json.set('lawyer_email', this.lawyerEmail);
        json.set('user_id', this.user_id);
        json.set('floor_apartment', this.claimetFloorApartament);
        json.set('tower', this.claimetTower);
        json.set('confirm_information', this.confirmInformation);
        json.set('egress_date', this.claimetEgressDate);
        json.set('reason_for_disengagement', this.claimetReasonForDisengagement);
        json.set('cause_disengagement', this.claimetCauseDisengagement);
        json.set('claimed_floor_apartment', this.claimedFloorApartment);
        json.set('claimed_tower', this.claimedTower);
        json.set('proceedings', this.proceedings);
        json.set('expediente_claim', this.expediente_claim);
        json.set('number_of_claim', this.number_of_claim);
        json.set('status', this.status);
        json.set('created', this.created);
        json.set('reason_for_rejection', this.reasonForRejection);
        json.set('conciliator_id', this.conciliatorId);
        json.set('conciliator_full_name', this.conciliatorFullName);
        json.set('is_borrador', this.is_borrador);
        json.set('department_number', this.department_number);
        json.set('department_name', this.department_name);
        json.set('id', this.id);
        return json;
    }

    static fromJson(data: Map<string, any>): ClaimEntity {
        return new ClaimModel(
            data.get('department_id'),
            data.get('last_name'),
            data.get('name'),
            data.get('cuil'),
            data.get('email'),
            data.get('phone'),
            data.get('province'),
            data.get('city'),
            data.get('neighborhood'),
            data.get('street'),
            data.get('number'),
            data.get('postal_code'),
            data.get('selected_type_of_job'),
            data.get('workday'),
            data.get('gross_remuneration'),
            data.get('type_remuneration'),
            data.get('date_admission'),
            data.get('number_agreement'),
            data.get('year'),
            data.get('category'),
            data.get('current_employment_status'),
            data.get('file_dni'),
            data.get('file_paycheck'),
            data.get('type_of_person_claimed'),
            data.get('business_name_claimed'),
            data.get('claimed_cuil'),
            data.get('claimed_email'),
            data.get('claimed_phone'),
            data.get('claimed_street'),
            data.get('claimed_number'),
            data.get('claimed_postal_code'),
            data.get('claimed_neighborhood'),
            data.get('claimed_province'),
            data.get('claimed_city'),
            data.get('object_of_claim'),
            data.get('text_claim_details'),
            data.get('extra_documentation'),
            data.get('selected_own_right'),
            data.get('full_name_lawyer'),
            data.get('registration_number'),
            data.get('lawyer_email'),
            data.get('user_id'),
            data.get('floor_apartment'),
            data.get('tower'),
            data.get('confirm_information'),
            data.get('egress_date'),
            data.get('reason_for_disengagement'),
            data.get('cause_disengagement'),
            data.get('claimed_floor_apartment'),
            data.get('claimed_tower'),
            data.get('proceedings'),
            data.get('expediente_claim'),
            data.get('number_of_claim'),
            data.get('status'),
            data.get('created'),
            data.get('reason_for_rejection'),
            data.get('conciliator_id'),
            data.get('conciliator_full_name'),
            data.get('is_borrador'),
            data.get('department_number'),
            data.get('department_name'),
            data.get('id'),
        );
    }
}
