import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ClaimStatus } from 'src/app/core/enums/claim-status.enum';
import { GetDepartmentsUseCase } from 'src/app/core/services/location/domain/usecases/get-departments.usecase';
import { Failure } from 'src/app/core/utils/failure';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { GetAllConciliatorsUseCase } from 'src/app/features/user/domain/usecases/get-all-conciliators.usecase';
import { Departament } from 'src/app/features/user/presentation/components/New-User-SuperAdmin/newUser.component';
import { ClaimFilterModel } from '../../../data/models/claim-filter.model';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';

@Component({
  selector: 'app-claim-filter',
  templateUrl: './claim-filter.component.html',
  styleUrls: ['./claim-filter.component.css'],
})
export class ClaimFilterComponent implements OnInit {
  stateList: string[] = Object.values(ClaimStatus);
  departmentList: Departament[] = [];
  conciliatorList: UserData[] = [];

  selectedStates: string[] = [];
  selectedDate?: Date;
  selectedDepartment?: string;
  selectedconciliator?: string;
  userId?: string;
  private AES = new AESEncryptDecryptService()

  @Input() showFilter: boolean = false;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() filters: EventEmitter<ClaimFilterModel> =
    new EventEmitter<ClaimFilterModel>();

  constructor(
    private getAllDepartments: GetDepartmentsUseCase,
    private getAllConciliators: GetAllConciliatorsUseCase,
    private messageService: MessageService,
    private localStorageService: LocalStorageServiceBase,  
  ) {}

  ngOnInit(): void {
    this.userId = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_rol')
    );
    this.getDepartments();
    if(this.userId !== 'Trabajador'){
      this.getConciliators();
    }
  }

  async getDepartments(): Promise<void> {
    const result: Departament[] | Failure =
      await this.getAllDepartments.execute();

    if (Array.isArray(result)) {
      this.departmentList = result;
    } else {
      this.errorMessage('error', 'Departamentos');
    }
  }

  async getConciliators(): Promise<void> {
    const result: UserData[] | Failure =
      await this.getAllConciliators.execute();

    if (Array.isArray(result)) {
      this.conciliatorList = result;
    } else {
      this.errorMessage('error', 'Conciliadores');
    }
  }

  private errorMessage(severity: string, name: string): void {
    this.messageService.add({
      severity: severity,
      summary: name,
      detail: `No se pudieron recuperar los ${name.toLocaleLowerCase()}`,
    });
  }

  cleanFilter(): void {
    this.selectedDate = undefined;
    this.selectedDepartment = undefined;
    this.selectedStates = [];
    this.selectedconciliator = undefined;
  }

  applyFilters(): void {
    this.filters.emit({
      conciliatorId: this.selectedconciliator,
      departmentId: this.selectedDepartment,
      fromDate: this.selectedDate,
      states: this.selectedStates,
    });
    this.closeFilter();
  }

  closeFilter(): void {
    this.showFilter = false;
  }
}
